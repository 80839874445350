import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Carousel, Container } from "react-bootstrap"

const SliderGallery = () => {
  const query = graphql`
    {
      allStrapiGalleries {
        nodes {
          id
          image_name
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  `

  const {
    allStrapiGalleries: { nodes: images },
  } = useStaticQuery(query)

  return (
    <section className="gallery">
      <Container className="my-5">
        <Carousel>
          {images.map((image, i) => {
            const path = getImage(image?.image?.localFile)
            return (
              <Carousel.Item key={i}>
                <GatsbyImage
                  className="img-fluid w-100"
                  image={path}
                  alt={image.image_name}
                />
              </Carousel.Item>
            )
          })}
        </Carousel>
      </Container>
    </section>
  )
}

export default SliderGallery
