import { graphql } from "gatsby"
import React from "react"
import ImageBanner from "../components/ImageBanner"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SliderGallery from "../components/SliderGallery"

const Gallery = ({ data }) => {
  const { strapiGalleryPage: info } = data

  return (
    <Layout>
      <Seo Sitetitle="Gallery" />
      <ImageBanner heading={info.banner_heading} img={info.banner_image.url} />
      <SliderGallery />
    </Layout>
  )
}

export const data = graphql`
  {
    strapiGalleryPage {
      banner_heading
      banner_image {
        url
      }
    }
  }
`

export default Gallery
